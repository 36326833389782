import React from 'react';
import { Row, Col } from 'react-bootstrap';



// Images
import picture1 from "~images/blog/img/eatblog1.png";
import picture2 from "~images/blog/img/eatblog2.png";
import picture3 from "~images/blog/img/eatblog3.png";
import picture4 from "~images/blog/img/eatblog4.png";
import picture5 from "~images/blog/img/eatblog5.png";
// Images
// import Avatar from "~images/blog/avatar.jpg";
// import PostSlide_1 from "~images/blog/post-slide-1.jpg";
// import RecentPost_2 from "~images/blog/recent-post-2.jpg";
// import RecentPost_3 from "~images/blog/recent-post-3.jpg";
// import People_1 from "~images/blog/people-1.jpg";
// import People_2 from "~images/blog/people-2.jpg";
// import PeopleNoImage from "~images/blog/people-no-image.jpg";

const BlogDetails = (props) => {
    return (
        <Col className="col-12 col-lg-8 res-margin">
						
            {/* <!-- Article --> */}
            <article className="row blog-post">
				<div className="col-12">
				<p>Have you ever heard this phrase “A clean home is a happy home” Sanitization is necessary whether you're cooking for loved ones or cooking for hungry customers who order from your menu from the EatCo App. Keeping a spotless kitchen is the absolute most significant piece of your arrangement interaction. Great kitchen cleanliness guarantees that individuals can partake in your delightful food while staying cheerful, solid, and straightforward - which is the key to keeping the customer coming back for more. </p>
						<h3>
							<a href="blog-post">Here are 5 steps to keep your kitchen clean.</a>
						</h3>
					<header>
					
						<h3>
							<a href="blog-post">1 – Wash hands frequently</a>
						</h3>
						<div className="info">
							{/* <span>Alex Wesly</span> */}
							<span><a href="/">Menu Structure</a></span>
							{/* <span>December 30, 2021</span> */}
						</div>
					</header>
					
					<figure>
						<img src={picture1} className="img-responsive img-rounded" alt="" />
					</figure>

					<p>
					Sanitization at home chef is necessary, every home chef makes sure to wash their hands regularly in order to maintain cleanliness. This step is something so important that it shouldn’t be skipped! Keep your hands clean throughout your cooking process by washing your hands after touching raw ingredients or any dust place. Additionally clean up after any action that expects you to contact different things including smoking, eating, drinking, contacting garbage, or contacting body parts including your face. Microorganisms wait wherever, even in surprising spots, so washing when you contact things all through your home guarantees that you keep your kitchen clean as you plan delicious dinners for your clients. 
					</p>

					<div className="post-footer clearfix">
						
						<ul className="post-meta">
					
							<li className="post-like d-none d-md-block">
								{/* <!-- Post counters --> */}
								<ul className="post-counters">
									<li>
										<a href="/">
											<i className="far fa-comment"></i> 3
										</a>
									</li>
									<li>
										<a href="/">
											<i className="far fa-heart"></i> 7
										</a>					
									</li>
								</ul>
							</li>

							<li className="post-read-more">
								{/* <!-- Read more --> */}
								<a href="blog-post" className="btn btn-default">Read More</a>
							</li>

							<li className="post-share d-none d-md-block">
								{/* <!-- Share --> */}
								<div className="share-panel share-btn">
									<p><i className="fas fa-share-alt"></i>Share</p>
									<ul>
										<li>
											<a href="/">
												<i className="fab fa-twitter"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-facebook-f"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-pinterest"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-linkedin-in"></i>
											</a>
										</li>
									</ul>
								</div>
							</li>
							
						</ul>
						
					</div>

				</div>
			</article>


            <article className="row blog-post">
				<div className="col-12">
					
					<header>
						<h3>
							<a href="blog-post">2 – Clean while you wait  </a>
						</h3>
						<div className="info">
							{/* <span>Alex Wesly</span> */}
							<span><a href="/">Menu Structure</a></span>
							{/* <span>December 30, 2021</span> */}
						</div>
					</header>
					
					<figure>
						<img src={picture2} className="img-responsive img-rounded" alt="" />
					</figure>

					<p>
					If you find yourself some free time in the kitchen, use it wisely.  Before you get to cooking, sanitize your cooking. Rinse them with clean water to remove extra soap or residue, then place your items & submerge them in a sanitizing solution. For instance, if you are waiting for a pot of water to boil, instead of standing around, do a bit of cleaning. Before you begin cooking wash any dirty dishes, clean away any clutter, and wipe down your countertops and sink throughout.  After doing all this you will be amazed how much time you can save and get things done between cooking.
					</p>

					<div className="post-footer clearfix">
						
						<ul className="post-meta">
					
							<li className="post-like d-none d-md-block">
								{/* <!-- Post counters --> */}
								<ul className="post-counters">
									<li>
										<a href="/">
											<i className="far fa-comment"></i> 3
										</a>
									</li>
									<li>
										<a href="/">
											<i className="far fa-heart"></i> 7
										</a>					
									</li>
								</ul>
							</li>

							<li className="post-read-more">
								{/* <!-- Read more --> */}
								<a href="blog-post" className="btn btn-default">Read More</a>
							</li>

							<li className="post-share d-none d-md-block">
								{/* <!-- Share --> */}
								<div className="share-panel share-btn">
									<p><i className="fas fa-share-alt"></i>Share</p>
									<ul>
										<li>
											<a href="/">
												<i className="fab fa-twitter"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-facebook-f"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-pinterest"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-linkedin-in"></i>
											</a>
										</li>
									</ul>
								</div>
							</li>
							
						</ul>
						
					</div>

				</div>
			</article>


            <article className="row blog-post">
				<div className="col-12">
					
					<header>
						<h3>
							<a href="blog-post">3 – Handle the disposal of waste and garbage properly </a>
						</h3>
						<div className="info">
							{/* <span>Alex Wesly</span> */}
							<span><a href="/">Menu Structure</a></span>
							{/* <span>December 30, 2021</span> */}
						</div>
					</header>
					
					<figure>
						<img src={picture3} className="img-responsive img-rounded" alt="" />
					</figure>

					<p>
					Cooking sometimes can be a mess that leads to having a ton of garage. Try not to allow your trash to heap onto you while you cook! Save separate removal techniques helpful for the various sorts of trash you'll make: one for jars or jugs, for example, a reusing container, and one for pieces of food, for example, a manure bowl. This will assist you with avoiding numerous means during your post-cleanup routine. It will lower the risk of any potential cross contamination of bacteria. 
					</p>

					<div className="post-footer clearfix">
						
						<ul className="post-meta">
					
							<li className="post-like d-none d-md-block">
								{/* <!-- Post counters --> */}
								<ul className="post-counters">
									<li>
										<a href="/">
											<i className="far fa-comment"></i> 3
										</a>
									</li>
									<li>
										<a href="/">
											<i className="far fa-heart"></i> 7
										</a>					
									</li>
								</ul>
							</li>

							<li className="post-read-more">
								{/* <!-- Read more --> */}
								<a href="blog-post" className="btn btn-default">Read More</a>
							</li>

							<li className="post-share d-none d-md-block">
								{/* <!-- Share --> */}
								<div className="share-panel share-btn">
									<p><i className="fas fa-share-alt"></i>Share</p>
									<ul>
										<li>
											<a href="/">
												<i className="fab fa-twitter"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-facebook-f"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-pinterest"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-linkedin-in"></i>
											</a>
										</li>
									</ul>
								</div>
							</li>
							
						</ul>
						
					</div>

				</div>
			</article>


            <article className="row blog-post">
				<div className="col-12">
					
					<header>
						<h3>
							<a href="blog-post">4 – Keep your meal packaging sanitized </a>
						</h3>
						<div className="info">
							{/* <span>Alex Wesly</span> */}
							<span><a href="/">Menu Structure</a></span>
							{/* <span>December 30, 2021</span> */}
						</div>
					</header>
					
					<figure>
						<img src={picture4} className="img-responsive img-rounded" alt="" />
					</figure>

					<p>
					Since the first thing your customer is going to taste is your food and if it's clean and germ free then what's better? Before you pack their meal, you should check the packaging thoroughly and see it is well sanitized then let them dry completely prior to pressing your dinner orders. For the most secure super packaging options for your to-go requests utilize new, dispensable bundling, for example, biodegradable and compostable food bundling things to limit single utilize plastic or Styrofoam waste and remain eco-accommodating.  
					</p>

					<div className="post-footer clearfix">
						
						<ul className="post-meta">
					
							<li className="post-like d-none d-md-block">
								{/* <!-- Post counters --> */}
								<ul className="post-counters">
									<li>
										<a href="/">
											<i className="far fa-comment"></i> 3
										</a>
									</li>
									<li>
										<a href="/">
											<i className="far fa-heart"></i> 7
										</a>					
									</li>
								</ul>
							</li>

							<li className="post-read-more">
								{/* <!-- Read more --> */}
								<a href="blog-post" className="btn btn-default">Read More</a>
							</li>

							<li className="post-share d-none d-md-block">
								{/* <!-- Share --> */}
								<div className="share-panel share-btn">
									<p><i className="fas fa-share-alt"></i>Share</p>
									<ul>
										<li>
											<a href="/">
												<i className="fab fa-twitter"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-facebook-f"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-pinterest"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-linkedin-in"></i>
											</a>
										</li>
									</ul>
								</div>
							</li>
							
						</ul>
						
					</div>

				</div>
			</article>


            <article className="row blog-post">
				<div className="col-12">
					
					<header>
						<h3>
							<a href="blog-post">5 – Cleaner way to keep stored ingredients</a>
						</h3>
						<div className="info">
							{/* <span>Alex Wesly</span> */}
							<span><a href="/">Menu Structure</a></span>
							{/* <span>December 30, 2021</span> */}
						</div>
					</header>
					
					<figure>
						<img src={picture5} className="img-responsive img-rounded" alt="" />
					</figure>

					<p>
					Now that your utensil and cooking area is clean, you need to make sure the fresh produce that you choose like fruits and vegetables should be washed under cold water. Cold water works just fine to safely remove dirt. Once your produce is clean, wash and chop your produce then immediately place it in the refrigerator to keep it fresh and safe. 
					</p>
					<p>Keeping the kitchen clean is more important because it affects health and cleaning the schedule is essential for your home-based restaurant because it ensures all food preparation areas are kept clean and sanitized at all times.</p>
					<div className="post-footer clearfix">
						
						<ul className="post-meta">
					
							<li className="post-like d-none d-md-block">
								{/* <!-- Post counters --> */}
								<ul className="post-counters">
									<li>
										<a href="/">
											<i className="far fa-comment"></i> 3
										</a>
									</li>
									<li>
										<a href="/">
											<i className="far fa-heart"></i> 7
										</a>					
									</li>
								</ul>
							</li>

							<li className="post-read-more">
								{/* <!-- Read more --> */}
								<a href="blog-post" className="btn btn-default">Read More</a>
							</li>

							<li className="post-share d-none d-md-block">
								{/* <!-- Share --> */}
								<div className="share-panel share-btn">
									<p><i className="fas fa-share-alt"></i>Share</p>
									<ul>
										<li>
											<a href="/">
												<i className="fab fa-twitter"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-facebook-f"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-pinterest"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-linkedin-in"></i>
											</a>
										</li>
									</ul>
								</div>
							</li>
							
						</ul>
						
					</div>

				</div>
			</article>
            
{/*             

            <div className="post-footer single-post-footer clearfix">
                
             
                <div className="tags">
                    <a href="/" className="tag">sunset</a> 
                    <a href="/" className="tag">venice</a> 
                    <a href="/" className="tag">canal</a>
                    <a href="/" className="tag">sea</a>
                    <a href="/" className="tag">gondola</a>
                </div>
                        
            
                <div className="share-panel share-btn">
                    <p><i className="fas fa-share-alt"></i>Share</p>
                    <ul>
                        <li>
                            <a href="/">
                                <i className="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="/">
                                <i className="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="/">
                                <i className="fab fa-pinterest"></i>
                            </a>
                        </li>
                        <li>
                            <a href="/">
                                <i className="fab fa-linkedin-in"></i>
                            </a>
                        </li>
                    </ul>
                </div>

                
                <ul className="post-counters hidden-xs">
                    <li>
                        <a href="/">
                            <i className="far fa-comment"></i> 12
                        </a>
                    </li>
                    <li>
                        <a href="/">
                            <i className="far fa-heart"></i> 5
                        </a>					
                    </li>
                </ul>

            </div>
            
 
            <hr />
            
     
            <div className="post-author text-center">

                <div className="avatar">
                    <a href="/">
                        <img src={Avatar} className="avatar-100 rounded-circle" width="100" height="100" alt="" />
                    </a>
                </div>

                <div className="description">
                    <span>
                        About Author
                    </span>
                    <h4>
                        <a href="/">AthenaStudio</a>
                    </h4>
                    <p>
                        Quisque rhoncus lectus sit amet ante iaculis maximus. 
                        Vivamus eget sodales purus. 
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                        Donec nec odio nulla.
                    </p>
                </div>

            </div>
            
            
            <div className="post-navigation-wrapper">

                <Row className="divider">
                    <Col className="col-12">

                        <nav className="navigation post-navigation">
                            <div className="nav-links">
                                <div className="nav-previous">
                                    <a href="/">
                                        <img src={RecentPost_2} alt="" />
                                        <div>
                                            <span>Previous Article</span>
                                            Forest Highway With Green Leaves
                                        </div>
                                    </a>
                                </div>
                                <div className="nav-next">
                                    <a href="/">
                                        <img src={RecentPost_3} alt="" />
                                        <div>
                                            <span>Next Article</span>
                                            Aerial Photography of Snowy Mountain Ranges
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </nav>

                    </Col>
                </Row>

            </div>
            
            
            <hr />
            
       
            <div className="comments">

                <Row>
                    <Col className="col-12">
                        <header>
                            <h3>3 Comments</h3>
                        </header>
                    </Col>
                </Row>

                <Row>
                    <Col className="col-12 clearfix">

                
                        <div className="user-comment">

                            <div className="user-comment-inner">
                                <div className="image">
                                    <img src={People_1} className="img-responsive rounded-circle" alt="" />
                                </div>

                                <div className="details">
                                    <div className="info">
                                        <span className="author">Rose Johnson</span>
                                        <span className="date"><span>Posted on</span> October 30, 2020</span>
                                    </div>
                                    <div className="text">
                                        <p>
                                            Quisque vulputate lorem a nisl faucibus, eu consectetur leo maximus. Nulla facilisis nisi nunc, ac tempor ipsum dapibus non. Curabitur non iaculis dui, eu tempus ipsum. Fusce ut porta urna. In sit amet pharetra ligula. Ut posuere quis elit laoreet efficitur.
                                        </p>
                                    </div>
                                    <div className="reply">
                                        <a href="#comment-form"><i className="fas fa-reply"></i> Reply</a>
                                    </div>
                                </div>
                            </div>

                        
                            <div className="user-comment bypostauthor">

                                <div className="user-comment-inner">
                                    <div className="image">
                                        <img src={People_2} className="img-responsive rounded-circle" alt="" />
                                    </div>

                                    <div className="details">
                                        <div className="info">
                                            <span className="author">Billie James</span>
                                            <span className="date"><span>Posted on</span> November 3, 2020</span>
                                        </div>
                                        <div className="text">
                                            <p>
                                                Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum fermentum ligula ut nisi mattis, ac laoreet odio pulvinar. Vivamus consequat.
                                            </p>
                                        </div>
                                        <div className="reply">
                                            <a href="#comment-form"><i className="fas fa-reply"></i> Reply</a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                       
                        <div className="user-comment">

                            <div className="user-comment-inner">
                                <div className="image">
                                    <img src={PeopleNoImage} className="img-responsive rounded-circle" alt="" />
                                </div>

                                <div className="details">
                                    <div className="info">
                                        <span className="author">Ashley Swift</span>
                                        <span className="date"><span>Posted on</span> October 28, 2020</span>
                                    </div>
                                    <div className="text">
                                        <p>
                                            Vivamus tincidunt bibendum urna eu auctor. Praesent sit amet tempor nisl. Duis eu enim eleifend, hendrerit dolor ut, tempus diam. Mauris porttitor, purus vel elementum porta, tortor diam vestibulum enim, in viverra metus libero nec mi. Etiam pretium velit.
                                        </p>
                                    </div>
                                    <div className="reply">
                                        <a href="#comment-form"><i className="fas fa-reply"></i> Reply</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                    </Col>
                </Row>

            </div> */}

            {/* <!-- Leave a comment --> */}
            <div className="comment-form" id="comment-form">

                <Row>
                    <Col className="col-12">
                        <header>
                            <h3>Leave a Comment</h3>
                            <p>Your email address will not be published or shared.</p>
                        </header>
                    </Col>
                </Row>

                <Row>

                    {/* <!-- Fields --> */}
                    <Col className="col-12 col-lg-6">
                        <div className="form-group mt-2 mb-3">
                            <input type="text" name="name" className="form-control field-name" placeholder="Name" />
                        </div>
                    </Col>

                    <Col className="col-12 col-lg-6">
                        <div className="form-group mt-2 mb-3">
                            <input type="email" name="email" className="form-control field-email" placeholder="Email" />
                        </div>
                    </Col>
                
                </Row>
                
                <Row>

                    <Col className="col-12 col-lg-12">
                        <div className="form-group mt-2 mb-3">
                            <textarea name="comment" className="form-control field-comment" rows="4" placeholder="Comment"></textarea>
                        </div>
                    </Col>

                </Row>

                <Row>
                    
                    {/* <!-- Submit button --> */}
                    <Col className="col-12 col-lg-12 mt-2">
                        <button type="submit" className="btn btn-default" id="contact-submit">Post Comment</button>
                    </Col>
                    
                </Row>

            </div>
            
        </Col>
    );
}

export default BlogDetails;